/*
 Theme Name:   Hyblinx
 Description:  Css File for website called Hyblinx
 Author:       Sho Tanaka
 Version:      1.0.0
*/

@import url('https://fonts.googleapis.com/earlyaccess/notosansjapanese.css');
@import url('https://fonts.googleapis.com/css2?family=Goldman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@mixin transform($transforms) {
  -webkit-transform: $transforms;
     -moz-transform: $transforms;
      -ms-transform: $transforms;
          transform: $transforms;
}

@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
      -ms-appearance: $appearance;
          appearance: $appearance;
}

@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
     -moz-box-sizing: $box-sizing;
      -ms-box-sizing: $box-sizing;
          box-sizing: $box-sizing;
}

* {
  color: #333333;
  font-family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
  letter-spacing: 0.1rem;
}

html {
  font-size: 12px;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: 480px) {
    font-size: 1vw;
  }
}

body {
  background: {
    blend-mode:lighten;
    color:rgba(255, 255, 255, 0.1);
    image: url('../public/img/background_pc.jpg');
    size: cover;
  }
  height: 100vh;
  line-height: 1.25;
  margin: 0;
  object-fit: cover;
  overflow-x: hidden;
  position: relative;

  @media screen and (max-width: 480px) {
    background: {
      image: url('../public/img/background_sp.jpg');
    }
  }
}

a {
  font-family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
  text-decoration: none;
}

img {
  @include box-sizing(border-box);
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  @include box-sizing(border-box);
  display: inline-block;
  font-family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
  letter-spacing: 0.1rem;
  list-style: none;
  vertical-align: top;
}

p {
  color: #333333;
  font: {
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
    size: 1.2rem;
  }
  margin: 0;
  line-height: 2rem;
}

input, textarea, button {
  @include appearance(none);
  @include box-sizing(border-box);
  border: none;
  color: #333333;
  display: block;
  font: {
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
    size: 1.5rem;
  }
  letter-spacing: 0.1rem;
  line-height: 2.5rem;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
}

select, option {
  @include appearance(none);
  background: none transparent;
  border: none;
  cursor: pointer;
  font: {
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
    weight: 500;
  }
  outline: none;
  padding: 0;

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
}

textarea {
  resize: none;

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
    line-height: 5rem;
  }
}

button, input[type="submit"] {
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
}

section {
  height: auto;
  position: relative;
  width: 100%;
}

table {
  border-collapse: collapse;
}

tr {
  margin: 0;
  padding: 0;
}

th, td {
  font: {
    size: 1rem;
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
  }
  letter-spacing: 0.3rem;
  text-align: left;
}

dl {
  margin: 0;
}

dd {
  font: {
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
    size: 1.4rem;
  }
  letter-spacing: 0.2rem;
}

h1, h2, h3, h4 {
  font: {
    family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
    weight: 500;
  }
  color: #333333;
  letter-spacing: 0.2rem;
  text-align: center;
}

.sp_only {
  display: none !important;

  @media screen and (max-width: 480px) {
    display: inline !important;
  }
}

.pc_only {
  display: inline;

  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.font_red {
  color: #fc0000;
}

header > .menu_area > .logo .logo_img > img {
  width: auto;
  height: 20rem;
  z-index: 30;
  transition: .1s;
  display: inline-block;
}

header {
  background: {
    color: #0a3e58;
  }
  border-bottom: 1px solid #063045;
  height: 6rem;
  position: fixed;
  width: 100%;
  z-index: 20;
  top: 0;

  @media screen and (max-width: 480px) {
    height: 15rem;
    position: fixed;
  }

  .menu_area {
    height: 100%;
    margin: 0 auto;
    position: relative;
    text-align: left;
    width: 100%;

    .logo {
      display: inline-block;
      height: 100%;
      margin-left: 3rem;
      padding-top: 1.25rem;
      position: relative;

      @media screen and (max-width: 480px) {
        height: 3.6rem;
        margin-left: 3rem;
        margin-top: 1.5rem;
        width: auto;
      }

      a {
        width: 100%;

        img {
          @include box-sizing(border-box);
          height: 3.6rem;
          transition: 0.5s;
          width: auto;

          @media screen and (max-width: 480px) {
            height: 10rem;
            width: auto;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    #panel {
      display: inline-block;
      height: 100%;
      position: absolute;
      right: 2rem;
      text-align: right;
      width: auto;
      z-index: 90;

      @media screen and (max-width: 480px) {
        background: {
          blend-mode:lighten;
          color:rgba(16, 75, 105, 0.7);
          image: url('../public/img/starry_sky.jpeg');
          size: cover;
        }
        box-shadow: 0px 0px 20px -5px #212121;
        color: #d6d1c4 !important;
        display: block;
        height: 100vh;
        margin-right: -25%;
        padding: 4rem;
        position: fixed;
        right: 0;
        transform: translateX(100vw);
        transition: all .3s ease-in-out;
        top: 0;
        width: 100%;
        z-index: 10;
      }

      .menu-logo {
        display: none;

        @media screen and (max-width: 480px) {
          display: block;
          margin: 5rem 0 0 3.75rem;
        }

        img {

          @media screen and (max-width: 480px) {
            display: block;
            height: auto;
            width: 37.5rem;
          }
        }
      }

      .menu {
        display: inline-block;
        padding-right: 2rem;
        vertical-align: top;

        @media screen and (max-width: 480px) {
          margin: 11rem 1rem 0;
          padding: 0;
          width: 100%;
        }

        li {
          @include box-sizing(border-box);
          display: inline-block;
          height: 100%;
          margin-left: 2rem;
          text-align: center;
          width: auto;

          @media screen and (max-width: 480px) {
            display: block;
            margin: 5rem 0 0 2.5rem;
            text-align: left;
            width: 100%;
          }

          &:nth-child(1) {

            @media screen and (max-width: 480px) {
              margin: 0 0 0 2.5rem;
            }
          }

          a {
            display: inline-block;
            color: #fff;
            font-size: 1.5rem;
            margin-top: 2rem;
            padding-bottom: 0.25rem;
            position: relative;
            transition: 1s;
            width: auto;

            @media screen and (max-width: 480px) {
              color: #fff;
              display: inline-block;
              font-size: 4rem;
              padding-bottom: 0.5rem;
              width: 100%;
            }

            &::after {
              @include transform(scale(0, 1));
              position: absolute;
              bottom: -4px;
              left: 0;
              content: '';
              width: 100%;
              height: 2px;
              background: #fff;
              transform-origin: center top;
              transition: transform .3s;
            }

            &:hover::after {
              @include transform(scale(1, 1));

              @media screen and (max-width: 480px) {
                display: none;
              }
            }
          }

          span {
            @media screen and (max-width: 480px) {
              font-size: 0.9rem;
              font-family: 'Inter', 'Noto Sans JP', 'メイリオ', sans-serif;
              color: #333333;
            }
          }
        }
      }

      .sns {
        display: none;
        text-align: left;
        width: 100%;

        @media screen and (max-width: 480px) {
          display: block;
          margin-left: 0.5rem;
          margin-top: 11rem;
        }

        li {
          display: inline-block;
          margin: 0 0 0 3rem;
          width: auto;

          &:nth-child(1) {
            margin: 0;
          }

          a {
            display: inline-block;
            margin: 0;
            width: auto;

            img {
              width: 11rem;
            }
          }
        }
      }

      .lang_selected {
        display: inline-block;
        margin-top: 1.9rem;

        @media screen and (max-width: 480px) {
          display: block;
          margin-left: 3rem;
          margin-top: 5rem;
          width: 6rem;
        }

        img {
          width: 2.25rem;

          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }
    }

    .is-active {
      transform: translateX(0) !important;
    }
  }

  .sp_menu {
    display: none;

    @media screen and (max-width: 480px) {
      height: auto;
      display: block;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 100;
    }

    #p-btn {
      background: transparent;
      height: 3.6rem;
      position: absolute;
      right: 2rem;
      top: 3rem;
      width: 8rem;
      z-index: 99;

      .icon {
        cursor: pointer;
        display: block;
        height: 9rem;
        margin: 0;
        position: relative;
        transition: .1s;
        width: 9rem;

        &:hover {
          span {
            transform: scale(1.2);
            transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
          }
        }

        &.open {
          cursor: pointer;
          margin-left: -9px;
          margin-top: -9px;
          transform: rotate(180deg);
          transition: .2s cubic-bezier(.8, .5, .2, 1.4);

          span {
            background-color: #cbcfd1;
            border-radius: 50%;
            transition-delay: 200ms;
            transition: .5s cubic-bezier(.8, .5, .2, 1.4);

            &:nth-child(2) {
              left: 5px;
              top: 5px;
            }

            &:nth-child(4) {
              left: 5px;
              top: 15.5px;
            }
            
            &:nth-child(6) {
              left: 15.5px;
              top: 5px;
            }
            
            &:nth-child(8) {
              left: 15px;
              top: 16px;
            }
          }
        }

        span {
          background-color: #ebe4d3;
          border-radius: 50%;
          display: block;
          height: 3px;
          position: absolute;
          width: 3px;

          &:nth-child(1) {
            left: 0;
            top: 0;
          }

          &:nth-child(2) {
            left: 10px;
            top: 0;
          }

          &:nth-child(3) {
            left: 21px;
            top: 0;
          }

          &:nth-child(4) {
            left: 0;
            top: 10px;
          }

          &:nth-child(5) {
            position: absolute;
            left: 10px;
            top: 10px;
          }

          &:nth-child(6) {
            left: 21px;
            top: 10px;
          }

          &:nth-child(7) {
            left: 0;
            top: 21px;
          }

          &:nth-child(8) {
            position: absolute;
            left: 10px;
            top: 21px;
          }

          &:nth-child(9) {
            left: 21px;
            top: 21px;
          }
        }
      }
    }
  }
}

.top_img {
  height: calc(100vh - 5rem);
  margin-top: 0;
  object-fit: cover;
  opacity: 0.25;
  position: relative;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: calc(100vh - 15rem);
  }
}

.container {
  margin-top: 5rem;
  position: relative;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 480px) {
    height: auto;
    margin-top: 0;
    padding-top: 15rem;
  }

  section {
    height: auto;
    position: relative;
    width: 100%;

    &.frame {
      padding: 7rem 0 8rem;

      .about {
        display: block;
        height: auto;

        ul {
          height: auto;
          position: relative;

          li {
            height: auto;

            @media screen and (max-width: 480px) {
              float: none;
            }

            img {
              @media screen and (max-width: 480px) {
                display: block;
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.white {
      background-color: #fff;
    }

    &.light-gray {
      background-color: #e8ebed;
    }

    &.theme {
      background-color: #0a3e58;
    }

    div {
      @media screen and (max-width: 480px) {
        width: 90%;
      }
    }

    .top-list {
      display: block;
      margin: auto;
      width: 75%;

      @media screen and (max-width: 480px) {
        width: 95%;
      }

      .top {
        display: inline-block;
        height: auto;
        padding-bottom: 0.5rem;
        text-align: left;
        width: auto;

        &::after {
          display: none;
        }

        a {
          display: inline-block;

          img {
            box-sizing: border-box;
            width: 15rem;
          }
        }
      }

      .post_content {
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        padding: 0 0 2.5rem;
        width: 100% !important;
      
        @media screen and (max-width: 480px) {
          margin: 0;
          padding: 0 0 4rem;

          h1 {
            display: block;
            margin: auto;
            padding: 0 0 4rem;
            width: 100%;

            a {
              display: inline-block;
              width: 100%;

              img {
                box-sizing: border-box;
                width: 25%;
              }
            }
          }
        }
      
        .main_box {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          text-align: center;
          width: 100%;
      
          @media screen and (max-width: 480px) {
            width: 100%;
          }
      
          .list {
            width: 100%;
      
            ul {
              font-size: 0;
              text-align: left;
              width: 100%;
      
              @media screen and (max-width: 480px) {
                padding: 0 0 6rem;
                text-align: center;
              }
      
              li {
                font-size: 0;
                position: relative;
                text-align: left;
                width: 25%;
      
                @media screen and (max-width: 480px) {
                  width: 100%;
                  margin: 0 0 5rem;
                }

                &.list-index {
                  border: 1px solid #e8ebed;
                  display: inline-block;
                  height: calc(22.5vw * 0.45);
                  margin: 0 0 -1px 0;
                  vertical-align: middle;

                  a {
                    display: block;
                    height: calc(22.5vw * 0.45);
                    position: relative;

                    img {
                      bottom: 0;
                      display: block;
                      height: auto;
                      left: 0;
                      margin: auto;
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 50%;
                    }

                    span {
                      bottom: 0.5rem;
                      color: #8b9194;
                      display: block;
                      font-size: 0.85rem;
                      margin: auto;
                      padding-left: 0.5rem;
                      position: absolute;
                      right: 0.5rem;
                      text-align: right;
                      width: 100%;

                      i {
                        color: #8b9194;
                        display: inline-block;
                        font-size: 0.75rem;
                        margin: 0.2rem 0 0 0.5rem;
                      }
                    }
                  }
                }
      
                a {
                  margin: 0;
                  display: flex;
                  align-items: center;
      
                  &:hover {
                    opacity: 0.8;
                    transition-duration: 0.3s;
                  }
      
                  img {
                    box-sizing: border-box;
                    height: calc(22.5vw * 0.45);
                    margin: 0;
                    object-fit: cover;
                    width: 100%;
      
                    @media screen and (max-width: 480px) {
                      height: calc(100vw * 0.45);
                    }
                  }
      
                  .title {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
                    font-size: 1.1rem;
                    font-weight: 600;
                    overflow-wrap: break-word;
                    padding: 1.5rem;
                    position: absolute;
      
                    p {
                      color: #fff;
                      font-size: 1.25rem;
      
                      @media screen and (max-width: 480px) {
                        font-size: 5rem;
                        line-height: 7.5rem;
                        padding: 4rem;
                      }
                    }
                  }
      
                  .overview {
                    background-color: rgba(255, 255, 255, 0.8);
                    bottom: 0;
                    box-sizing: border-box;
                    padding-left: 0.5rem;
                    position: absolute;
                    width: 100%;
      
                    @media screen and (max-width: 480px) {
                      padding: 1.5rem 2rem;
                    }
      
                    i {
                      display: inline-block;
                      font-size: 0.9rem;
                      padding-right: 0.5rem;
      
                      @media screen and (max-width: 480px) {
                        font-size: 3rem;
                        padding-right: 2rem;
                      }
                    }
      
                    p {
                      display: inline-block;
                      font-size: 0.9rem;
                      padding-right: 1.5rem;
      
                      @media screen and (max-width: 480px) {
                        font-size: 3rem;
                        padding-right: 2.5rem;
                      }
                    }
      
                    .note-suki {
                      color: #EB4667;
                    }
                  }

                  &.zenn {
                    .thumbnail {
                      display: flex;
                      align-items: center;
                      height: calc(22.5vw * 0.45);
                      width: 100%;
      
                      @media screen and (max-width: 480px) {
                        height: calc(100vw * 0.45);
                      }
  
                      &.idea {
                        background-color: #DBDFFF;
                      }
  
                      &.tech {
                        background-color: #CFE5FF;
                      }
  
                      p {
                        box-sizing: border-box;
                        color: #65717b;
                        display: inline-block;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
                        font-size: 1.1rem;
                        font-weight: 600;
                        letter-spacing: 0.05rem;
                        margin-top: -1.5rem;
                        overflow-wrap: break-word;
                        padding: 0 1.5rem;
                        width: 100%;
      
                        @media screen and (max-width: 480px) {
                          padding: 0 4rem;
                        }

                        &.emoji {
                          font-size: 5rem;
                          text-align: center;
                          margin-top: -2.25rem;
                          opacity: 0.15;
      
                          @media screen and (max-width: 480px) {
                            font-size: 16rem;
                            margin-top: -7.5rem;
                          }
                        }

                        &.title {
                          color: #4b4f52;
                          display: block;
                          font: {
                            size: 1.25rem;
                            weight: 700;
                          }
                          text-align: left;
        
                          @media screen and (max-width: 480px) {
                            font: {
                              size: 5rem;
                            }
                            letter-spacing: 0.25rem;
                            line-height: 7.5rem;
                            margin-top: -8rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      
        .side_box {
          box-sizing: border-box;
          display: inline-block;
          font-size: 1.25rem;
          margin-left: 2rem;
          text-align: left;
          vertical-align: top;
          width: 25%;
      
          @media screen and (max-width: 480px) {
            margin: 0 auto;
            width: 100%;
          }
      
          .title {
            background-color: #0a3e58;
            box-sizing: border-box;
            color: #fff;
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
            font-weight: 600;
            padding: 1rem;
            width: 100%;
      
            @media screen and (max-width: 480px) {
              font-size: 4rem;
              margin-top: 8rem;
              padding: 2rem;
            }
          }
      
          a {
            background-color: #fff;
            box-sizing: border-box;
            display: block;
            font-weight: 600;
            letter-spacing: 0.025rem;
            padding: 1rem;
            width: 100%;
      
            &:not(:last-child) {
              border-bottom: 1px solid #D0D7DD;
            }
      
            @media screen and (max-width: 480px) {
              font-size: 3.75rem;
              padding: 4rem 2rem;
            }
      
            .date-time {
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-align-items: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-bottom: 0.5rem;
      
              .created {
                color: #a2a8ab;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
                font-size: 1rem;
                font-weight: 400;
                letter-spacing: 0.1rem;
                line-height: 1.5;
                margin-right: 1.25rem;
      
                @media screen and (max-width: 480px) {
                  font-size: 3.5rem;
                }
              }
            }
      
            .tag {
              -webkit-align-items: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              color: rgba(0,0,0,0.6);
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-top: 0.5rem;
              font-weight: 400;
      
              @media screen and (max-width: 480px) {
                margin-top: 1.5rem;
              }
        
              i {
                color: #8a8a8a !important;
                font-size: 1.25rem;
                margin-right: 0.5rem;
      
                @media screen and (max-width: 480px) {
                  font-size: 3rem;
                  margin-right: 1.5rem;
                }
              }
        
              .tag_content {
                color: #8a8a8a !important;
                margin-right: 4px;
                font-size: 1rem;
                line-height: 1.5rem;
                font-family: -apple-system, "Segoe UI", "Helvetica Neue", "Hiragino Kaku Gothic ProN", メイリオ, meiryo, sans-serif !important;
                letter-spacing: 0.075rem;
      
                @media screen and (max-width: 480px) {
                  font-size: 3rem;
                }
        
                &:not(:last-child)::after {
                  content: ',';
                  margin-right: 4px;
                }
              }
            }
          }
      
          .second-block {
            margin-top: 2rem;
      
            @media screen and (max-width: 480px) {
              margin-top: 8rem;
            }
          }
      
          .tag_list {
            background-color: #fff;
            box-sizing: border-box;
            padding: 1rem;
            width: 100%;
      
            @media screen and (max-width: 480px) {
              padding: 4rem 2rem;
            }
      
            ul {
              li {
                background-color: #e8ebed;
                border-radius: 5px;
                margin: 0 0.5rem 0.5rem 0;
                
                a {
                  background-color: transparent;
                  box-sizing: border-box;
                  font-size: 0.9rem;
                  font-weight: 400;
                  padding: 0.5rem;
                  letter-spacing: 0.05rem;
      
                  @media screen and (max-width: 480px) {
                    font-size: 3rem;
                    padding: 1rem 1.5rem;
                  }
                }
              }
            }
          }
      
          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }
    }

    h1 {
      text-align :left;
  
      @media screen and (max-width: 480px) {
        font-size: 4rem;
      }

      &.top {
        position: relative;
        width: 100%;
        font-size: 2.5rem;
        height: 1rem;
        text-align: center;
        padding: 0 0 7rem;
        letter-spacing: 0.3rem;
        margin: 0 !important;
        
        @media screen and (max-width: 480px) {
          font-size: 5rem;
          height: 3.5rem;
          padding : 0 0 7rem;
        }

        &::after {
          position: absolute;
          bottom: 0;
          left: calc(50% - 4rem);
          width: 8rem;
          content: '';
          display: block;
          padding: 0 0 0.1rem;
          border-bottom: 0.3rem solid #0a3e58;
          margin: 0 0 4rem;
        
          @media screen and (max-width: 480px) {
            border-bottom: 0.8rem solid #0a3e58;
            left: calc(50% - 4.5rem);
            margin: 0 0 1.8rem;
            width: 9rem;
          }
        }
      }
    }

    a {
      &.link-button {
        background-color: #0a3e58;
        color: #fff;
        display: block;
        font-size: 1.5rem;
        margin: 4rem auto 0;
        padding: 1.5rem;
        text-align: center;
        transition-duration: 0.3s;
        width: 20rem;
        
        @media screen and (max-width: 480px) {
          font-size: 3rem;
          margin: 6rem auto 0;
          padding: 3rem;
          width: 25rem;
        }

        &:hover {
          opacity: 0.8;
          transition-duration: 0.3s;
        }

        i {
          color: #fff;
        }
      }
    }
  }
}

.title_box {
  font-size: 1.75rem;
  margin: 0 auto;
  padding: 0 4rem;
  width: 80%;

  @media screen and (max-width: 480px) {
    padding: 6rem 4rem;
  }

  h1 {
    margin: 0;

    @media screen and (max-width: 480px) {
      font-size: 7.5rem !important;
    }

    span {
      color: #6c6d70;
      display: block;
      font-size: 1.45rem;
      padding: 0.5rem 0 0 0.1rem;

      @media screen and (max-width: 480px) {
        font-size: 3.75rem;
        padding: 0.75rem 0 0 0.25rem;
      }
    }
  }
}

#drop_dark {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  transition: background-color 0.5s;
  top: 0;
  width: 100vw;
  z-index: 5;
}

.dark {
  background-color: rgba(0, 0, 0, 0.5);
  display: block !important;
}

.in_front {
  z-index: 999;
}

.in_background {
  z-index: -1;
}

.top_tattoo {
  bottom: 3rem;
  display: block;
  left: 3rem;
  position: absolute;
  width: 28rem;
  z-index: 30;
  animation:35s linear infinite rotation1;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

@keyframes rotation1 {
  0% {
    @include transform(rotate(0));
  }

  100% {
    @include transform(rotate(360deg));
  }
}

@keyframes progress-0 { 0% { width: 0 } 100% { width: 0% } }
@keyframes progress-10 { 0% { width: 0 } 100% { width: 10% } }
@keyframes progress-20 { 0% { width: 0 } 100% { width: 20% } }
@keyframes progress-40 { 0% { width: 0 } 100% { width: 40% } }
@keyframes progress-60 { 0% { width: 0 } 100% { width: 60% } }
@keyframes progress-80 { 0% { width: 0 } 100% { width: 80% } }
@keyframes progress-100 { 0% { width: 0 } 100% { width: 100% } }

.notice {
  background-color: #0A3E58;
  color: #fff;
  display: block;
  font: {
    size: 1rem;
    weight: 500;
  }
  margin-right: -55rem;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 5rem;
  transition: margin 0.25s;
  transition-delay: 1.8s;
  transition-timing-function: ease;
  width: 39rem;

  @media screen and (max-width: 480px) {
    height: auto;
    margin-right: -95rem;
    bottom: 25rem;
    width: 72rem !important;
  }

  .heading {
    background-color: #05202e;
    box-sizing: border-box;
    padding: 0.35rem 0 0.35rem 1rem;
    width: 100%;
  
    @media screen and (max-width: 480px) {
      padding: 1rem 2.5rem;
    }

    span {
      color: #cbcfd1;
      font-size: 0.9rem;
  
      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }

    ul {
      display: inline-block;
      height: 100%;
      left: 1rem;
      position: relative;
      top: 0.4rem;
  
      @media screen and (max-width: 480px) {
        left: 4rem;
        top: -0.5rem;
      }
  
      li {
        animation: signal ease 1s infinite;
        border-radius: 50%;
        content: "　";
        display: inline-block;
        height: 0.55rem;
        margin: 0 0.25rem;
        width: 0.55rem;
        transition: background-color 0.25s;
        transition-timing-function: ease;
  
        @media screen and (max-width: 480px) {
          height: 1.75rem;
          width: 1.75rem;
          margin: 0 0.5rem;
        }
  
        &:nth-child(1) { animation-delay: 0s; margin-left: 0; }
        &:nth-child(2) { animation-delay: 0.25s; }
        &:nth-child(3) { animation-delay: 0.5s; }
        &:nth-child(4) { animation-delay: 0.75s; }
        &:nth-child(5) { animation-delay: 1s; }
      }
    }
  }

  p {
    color: #fff;
    font-size: 1.1rem;
    padding: 0.65rem 0 0.65rem 1rem;

    @media screen and (max-width: 480px) {
      font-size: 14px;
      line-height: 22px;
      padding: 1rem 2rem 2.5rem 2.5rem;
    }
  }
}

@keyframes signal {
  0%   {background-color: #e69467}
  25%  {background-color: #ffc079}
  50%  {background-color: #ffd28a}
  75%  {background-color: #ffe09a}
  100% {background-color: #fffec7}
}

.hide {
  display: none;
}

.about-top {
  margin: 0 auto;
  padding: 2.5rem 0;
  text-align: left;
  width: 60%;

  &::after {
    background: url('../public/img/icon.png') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: calc(30rem * 1.036);
    position: absolute;
    right: 23rem;
    top: 7rem;
    width: 30rem;
    z-index: -1;

    @media screen and (max-width: 480px) {
      height: calc(60rem * 1.036);
      opacity: 0.5;
      right: 23rem;
      top: 10rem;
      width: 60rem;
    }
  }

  h1 {
    display: inline-block;
    font: {
      size: 6rem;
    }
    letter-spacing: 0.05rem;
    margin: 0;
    position: relative;
    text-align: left;
    width: auto;

    @media screen and (max-width: 480px) {
      font: {
        size: 10rem !important;
      }
      letter-spacing: 0.1rem;
    }
  }

  h2 {
    letter-spacing: 0.05rem;
    margin: 0;
    text-align: left;

    @media screen and (max-width: 480px) {
      font: {
        size: 3.5rem !important;
      }
      letter-spacing: 0.1rem;
      padding: 1rem 0 2rem;
    }
  }

  .summary {
    box-sizing: border-box;
    counter-reset: list;
    display: block;
    list-style-type: none;
    padding: 3rem 0;
    width: 100%;

    @media screen and (max-width: 480px) {
      padding: 5rem 2rem 2rem 0 !important;
    }

    li {
      display:list-item;
      font-size: 1.25em;
      line-height: 2rem;
      list-style: none;
      margin-left: 0.5rem;
      position:relative;
      padding: 0.5rem 0 0 1.5rem;
      text-align: left;
      width: 100%;

      @media screen and (max-width: 480px) {
        font-size: 3.5rem !important;
        line-height: 5rem !important;
        padding: 0.5rem 0 2.5rem 5rem;
        width: calc(100% - 24px);
      }
      
      &::before {
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 7px solid #0a3e58;
        counter-increment: list;
        content: '';
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
      }

      &:nth-child(1) {
        padding-top: 0;
      }
    }
  }

  a {
    margin: 0 !important;
  }
}

.about {
  display: block;
  margin: 6rem auto;
  position: relative;
  text-align: left;
  width: 80%;

  @media screen and (max-width: 480px) {
    margin: 0 auto;
    width: 100% !important;
  }

  h3 {
    border-bottom: solid 2px #333;
    display: inline-block;
    font-size: 2.5rem;
    line-height: 4rem;
    margin: {
      bottom: 2.5rem;
      top: 0;
    }
    padding: 0 0 0.25rem;
    text-align: left;
    width: auto;

    @media screen and (max-width: 480px) {
      display: inline-block;
      font-size: 6rem;
      margin: 0 auto 8rem;
      line-height: 8rem;
      padding: 2rem;
      text-align: center;
      width: auto;
    }
  }

  .profile {
    display: block;
    margin-bottom: 2.5rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin: 0 auto 2.5rem;
      text-align: center;
      width: 95% !important;
    }

    ul {
      -ms-grid-columns: auto auto auto;
      display: -ms-grid;
      display: grid;
      grid-template-columns: auto 0.75fr;
      height: auto;
      text-align: center;
    
      @media screen and (max-width: 480px) {
        display: block;
      }

      li {
        height: auto;
        width: 30%;
    
        @media screen and (max-width: 480px) {
          display: block;
          width: 100%;
        }

        img {
          @include box-sizing(border-box);
          display: block;
          height: calc(24vw * 1.5);
          object-fit: cover;
          position: relative;
          width: 100%;
    
          @media screen and (max-width: 480px) {
            height: auto;
          }
        }

        &:nth-child(1) {
          -ms-grid-row: 1;
          grid-row: 1;
          width: 100%;
    
          @media screen and (max-width: 480px) {
            display: block;
            padding-bottom: 2rem;
            width: 100%;
          }
        }

        &:nth-child(2) {
          -ms-grid-row: 1;
          grid-row: 1;
          width: 100%;
          padding-right: 10%;
    
          @media screen and (max-width: 480px) {
            padding-right: 0;
          }
    
          h2 {
            font-size: 1.5rem;
            margin-top: 0;
            text-align: left;
    
            @media screen and (max-width: 480px) {
              font-size: 4rem;
              height: calc(100vw * 1.1);
              line-height: 5rem;
              padding-right: 0;
            }
          }
    
          p {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0.2rem;
            line-height: 3rem;
            margin-bottom: 1rem;
            text-align: left;
    
            @media screen and (max-width: 480px) {
              font-size: 3.5rem;
              line-height: 6rem;
            }
          }
        }

        table {
          display: block;
          padding-bottom: 2rem;

          @media screen and (max-width: 480px) {
            margin-bottom: 2rem;
          }

          tr {
            th, td {
              font-size: 1.5rem;
              font-weight: 400;
              line-height: 2.5rem;
              padding-bottom: 1rem;
              vertical-align: top;
    
              @media screen and (max-width: 480px) {
                font-size: 3.5rem;
                line-height: 6rem;
              }
            }

            th {
              width: 25%;
    
              @media screen and (max-width: 480px) {
                display: block;
                width: 100%;
              }
            }

            td {
              width: 75%;
    
              @media screen and (max-width: 480px) {
                display: block;
                width: 100%;
              }

              span {
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1.75rem;
      
                @media screen and (max-width: 480px) {
                  font-size: 3rem;
                  line-height: 4rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .career {
    display: block;
    margin-bottom: 2.5rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin: 0 auto 2.5rem;
      text-align: center;
      width: 95% !important;
    }

    table {
    
      @media screen and (max-width: 480px) {
        margin-bottom: 8rem;
      }

      tr {
        th, td {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2.5rem;
          padding-bottom: 2rem;
          vertical-align: top;
    
          @media screen and (max-width: 480px) {
            font-size: 3.5rem;
            line-height: 6rem;
          }
        }
        th {
          width: 15%;
    
          @media screen and (max-width: 480px) {
            display: block;
            width: 100%;
          }
        }
        td {
          width: 85%;
    
          @media screen and (max-width: 480px) {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  .skill {
    display: block;
    margin-bottom: 2.5rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin: 0 auto 2.5rem;
      text-align: center;
      width: 95% !important;
    }

    table {
    
      @media screen and (max-width: 480px) {
        margin-bottom: 8rem;
      }

      tr {
        th, td {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2.5rem;
          padding-bottom: 2rem;
          vertical-align: top;
    
          @media screen and (max-width: 480px) {
            font-size: 3.5rem;
            line-height: 6rem;
          }
        }
        th {
          width: 15%;
    
          @media screen and (max-width: 480px) {
            display: block;
            width: 100%;
          }
        }
        td {
          width: 85%;
    
          @media screen and (max-width: 480px) {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  .new_about {
    display: block;
    margin-bottom: 2.5rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      margin: 0 auto 2.5rem;
      text-align: center;
      width: 95% !important;
    }

    h2 {
      text-align: left;

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }

    .base {
      padding: 5rem 0;

      @media screen and (max-width: 480px) {
        padding: 20rem 0 15rem;
        text-align: left;
      }

      h1 {
        display: inline-block;
        font: {
          size: 8rem;
        }
        letter-spacing: 0.05rem;
        margin-bottom: 0;
        position: relative;
        text-align: left;
        width: auto;

        @media screen and (max-width: 480px) {
          font: {
            size: 12rem;
          }
          margin: 0;
        }

        &::after {
          background: url('../public/img/icon.png') no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: calc(45rem * 1.036);
          position: absolute;
          right: -23rem;
          top: -20rem;
          width: 4rem;
          z-index: -1;

          @media screen and (max-width: 480px) {
            top: -16.5rem;
          }
        }
      }

      h2 {
        color: #5e5e5f;
        font: {
          size: 1.75rem;
        }
        letter-spacing: 0.05rem;
        margin: 0.25rem 0 0;
        padding-left: 0.5rem;
        text-align: left;
        width: 100%;

        @media screen and (max-width: 480px) {
          font: {
            size: 4rem;
          }
          padding-left: 0;
        }
      }
    }
    
    .policy {
      padding: 0;
      text-align: center;

      img {
        border-radius: 50%;
        display: block;
        filter: grayscale(30%);
        margin: 0 auto;
        width: 175px;
      }

      h1 {
        font: {
          size: 3rem;
        }
        letter-spacing: 0.05rem;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
      }

      h2 {
        font: {
          size: 1.25rem;
        }
        letter-spacing: 0.05rem;
        text-align: center;
        width: 100%;
      }

      .catch_copy {
        @include box-sizing(border-box);
        margin: 0;
        padding: 0;
        text-align: left;
        
        @media screen and (max-width: 480px) {
          display: block;
          margin: auto 0 15rem;
          padding: 6rem 0 0;
          width: 95% !important;
        }
      
        h5 {
          color: #ebe4d3;
          font: {
            size: 5rem;
            weight: 500;
          }
          display: inline-block;
          line-height: 5rem;
          margin: 0 0 2rem;
          padding: 0 0 0.5rem;
        
          @media screen and (max-width: 480px) {
            font-size: 15rem !important;
            line-height: 15rem;
            margin-bottom: 0;
          }
        }
      }

      p {
        color: #fff;
        padding-top: 1.75rem;
        text-align: left;

        @media screen and (max-width: 480px) {
          font-size: 3em;
          line-height: 5.5rem;
          padding: 0 0 5rem;
        }
      }
    }
    
    .block {
      padding-bottom: 5rem;

      ul {
        text-align: left;

        li {
          font-size: 1.25em;
          line-height: 2rem;
          padding-top: 2rem;
          width: 100%;

          @media screen and (max-width: 480px) {
            font-size: 3rem;
            line-height: 5rem;
            padding-top: 5rem;
          }

          .during {
            font-weight: 700;
          }

          .charge {
            color: #7f8385;
          }

          .remark {
            color: #7f8385;
          }
        }
      }
    }

    .profile {
      text-align: center;

      ul {
        box-shadow: 0 0 4px gray;
        display: block;
        padding: 2rem;

        @media screen and (max-width: 480px) {
          padding: 4rem 2rem;
        }

        li {
          padding: 0;
          width: 49%;

          @media screen and (max-width: 480px) {
            padding-bottom: 5rem;
            width: 100%;
          }

          &:nth-child(2) {
            @media screen and (max-width: 480px) {
              padding-bottom: 0;
            }
          }
          
          h4 {
            font-size: 1.75rem;
            display: block;
            line-height: 1.75rem;
            margin: 0 0 2rem;
            padding: 0 0 0.5rem;
            text-align: left;

            @media screen and (max-width: 480px) {
              font-size: 4rem;
              line-height: 5.5rem;
              margin: 0;
            }
          }

          dl {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (max-width: 480px) {
              margin-bottom: 3rem;
            }

            dt {
              font-size: 1.1rem;
              font-weight: 700;
              letter-spacing: 0;
              padding: 0.3em 1.0em 0.5em 2em;
              position: relative;
              text-align: left;
              width: auto;

              @media screen and (max-width: 480px) {
                font-size: 3.25rem;
                line-height: 4rem;
                padding: 0.4em 0 0.5em 1.5em;
              }

              &::after {
                background-color: #57a5bd;
                border-radius: 50%;
                content: "";
                font-size: 1.2rem;
                height: 1.25rem;
                left: 0.2rem;
                position: absolute;
                top: 0.475rem;
                width: 1.25rem;

                @media screen and (max-width: 480px) {
                  font-size: 1.25em;
                  height: 3rem;
                  left: -0.35rem;
                  top: 2rem;
                  width: 3rem;
                }
              }
            }

            dd {
              border-left: 2px solid #ccc;
              margin-left: 0.75rem;
              padding: 0.25em 2.0em 1em;
              width: calc(100%);

              h2 {
                font-size: 1.15rem;
                margin: 0;

                @media screen and (max-width: 480px) {
                  font-size: 3.15rem;
                  line-height: 4.5rem;
                  text-align: left;
                  width: 100%;
                }
    
                span {
                  color: #8b9094;
                  font-size: 1.1rem;

                  @media screen and (max-width: 480px) {
                    font-size: 2.9rem;
                  }
                }
              }

              p {
                color: #a2a7ab;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.75rem;
                padding-top: 0.5rem;
                text-align: left;

                @media screen and (max-width: 480px) {
                  font-size: 2.75rem;
                  line-height: 4rem;
                  padding-top: 2rem;
                  margin-bottom: 1rem;
                }
              }
            }
          }

          ul {
            box-shadow: none;
            box-sizing: border-box;
            padding: 0;
          }

          .discography {
            display: block;
            width: 100%;

            @media screen and (max-width: 480px) {
              padding-bottom: 5.5rem;
            }

            li {
              display: block;
              font-size: 1.25em;
              line-height: 2rem;
              padding-top: 2rem;
              text-align: left;
              width: 100%;

              @media screen and (max-width: 480px) {
                line-height: 4rem;
                padding-top: 5rem;
                padding-bottom: 0;
              }

              &:nth-child(1) {
                padding-top: 0;

                @media screen and (max-width: 480px) {
                  padding-top: 4rem;
                }
              }
            }
          }

          .summary {
            box-sizing: border-box;
            counter-reset: list;
            display: block;
            list-style-type: none;
            padding: 0 1rem 3rem 0;
            width: 100%;
        
            @media screen and (max-width: 480px) {
              padding: 3rem 2rem 7rem 0 !important;
            }
        
            li {
              display: list-item;
              font-size: 1.25em;
              line-height: 2rem;
              list-style: none;
              margin-left: 0.5rem;
              position:relative;
              padding: 0.5rem 0 0 1.5rem;
              text-align: left;
              width: 100%;
        
              @media screen and (max-width: 480px) {
                font-size: 3.5rem !important;
                line-height: 5rem !important;
                padding: 0.5rem 0 2.5rem 5rem;
                width: calc(100% - 24px);
              }
        
              &:nth-child(1) {
                padding-top: 0;
              }

              &::before {
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 7px solid #0a3e58;
                counter-increment: list;
                content: '';
                display: block;
                height: 0;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
              }
            }
          }

          table {
            margin-bottom: 0.5rem;
            width: 100%;

            @media screen and (max-width: 480px) {
              display: table;
              margin: 2rem 0 5rem;
            }

            tr {
              @media screen and (max-width: 480px) {
                text-align: left;
                width: 100%;
              }

              th, td {
                font-size: 1.25em;
                letter-spacing: 0.05rem;
                line-height: 2rem;
                padding: 0.25rem 0;

                @media screen and (max-width: 480px) {
                  display: inline-block;
                  font-size: 3.5rem;
                  line-height: 7rem;
                  width: 49%;
                }
              }

              th {
                @media screen and (max-width: 480px) {
                  width: 25%;
                }
              }

              td {
                @media screen and (max-width: 480px) {
                  width: 74%;
                }
              }
            }
          }

          .sns {
            display: block;
            padding-top: 1rem;
            text-align: left;

            li {
              float: none;
              height: auto;
              width: auto;
              margin-right: 1rem;
      
              @media screen and (max-width: 480px) {
                display: inline-block;
                margin-right: 2rem;
                padding-bottom: 0;
              }

              a {
                display: block;
                transition-duration: 0.3s;

                img {
                  height: 4rem;
                  padding: 0;
                  width: 4rem;
      
                  @media screen and (max-width: 480px) {
                    height: 10rem;
                    width: 10rem;
                  }
                }

                &:hover {
                  opacity: 0.8;
                  transition-duration: 0.3s;
                }
              }
            }
          }
        }
      }
    }

    .new_about2 {
      margin: auto;
      text-align: center;
      width: 55%;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      p {
        padding-bottom: 2rem;
        text-align: left;

        @media screen and (max-width: 480px) {
          font-size: 3em;
          line-height: 5.5rem;
          padding-bottom: 5rem;
        }
      }
    }
    
    .project {
      padding-bottom: 5rem;
      margin: auto;
      text-align: center;
      width: 60%;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      ul {
        text-align: left;

        li {
          font-size: 1.25em;
          line-height: 2rem;
          padding-top: 2rem;
          width: 100%;

          @media screen and (max-width: 480px) {
            line-height: 4rem;
            padding-top: 5rem;
            padding-bottom: 0;
          }

          .during {
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0;

            @media screen and (max-width: 480px) {
              font-size: 2.5em;
              line-height: 4rem;
              width: 100%;
            }
          }

          .title {
            font-size: 1.25rem;

            @media screen and (max-width: 480px) {
              font-size: 3.5rem;
              line-height: 5rem;
              width: 100%;
            }

            span {
              color: #a2a7ab;
              font-size: 1.1rem;

              @media screen and (max-width: 480px) {
                display: none;
              }
            }
          }

          .charge {
            color: #a2a7ab;
            font-size: 1.1rem;

            @media screen and (max-width: 480px) {
              font-size: 2.25em;
              line-height: 5.25rem;
              width: 100%;
            }
          }

          .remark {
            color: #a2a7ab;

            @media screen and (max-width: 480px) {
              font-size: 2.25em;
              line-height: 5.25rem;
              width: 100%;
            }
          }
        }
      }
    }

    .skill {
      margin: 0 auto;
      text-align: center;
      width: 100%;

      ul {
        background-color: #fff;
        box-shadow: 0 0 4px gray;
        padding: 2rem;

        @media screen and (max-width: 480px) {
          padding: 5rem 2rem 2rem;
        }

        li {
          display: inline-block;
          letter-spacing: 0.05rem;
          width: 49%;

          @media screen and (max-width: 480px) {
            width: 100%;
          }

          table {
            @media screen and (max-width: 480px) {
              display: table;
              margin-bottom: 7rem;
              width: 100%;
            }

            tr {
              @media screen and (max-width: 480px) {
                text-align: left;
                width: 100%;
              }

              th, td {
                font-size: 1.25em;
                letter-spacing: 0.05rem;
                line-height: 2rem;
                padding: 0.5rem 0;

                @media screen and (max-width: 480px) {
                  display: inline-block;
                  font-size: 3.5rem;
                  line-height: 7rem;
                  width: 49%;
                }
              }

              th {
                margin: auto;
                width: 55%;

                @media screen and (max-width: 480px) {
                  width: 45%;
                }
              }

              td {
                @media screen and (max-width: 480px) {
                  width: auto;
                }
              }
            }
          }

          .summary {
            box-sizing: border-box;
            counter-reset: list;
            display: block;
            list-style-type: none;
            padding: 0 1rem 0 0;
            width: 100%;
        
            @media screen and (max-width: 480px) {
              padding: 2rem 2rem 2rem 0 !important;
            }
        
            li {
              display:list-item;
              font-size: 1.25em;
              line-height: 2rem;
              list-style: none;
              margin-left: 0.5rem;
              position: relative;
              padding: 0.5rem 0 0 1.5rem;
              text-align: left;
              width: 100%;
        
              @media screen and (max-width: 480px) {
                font-size: 3.5rem !important;
                line-height: 5rem !important;
                padding: 0.5rem 0 2.5rem 5rem;
                width: calc(100% - 24px);
              }
        
              &:nth-child(1) {
                padding-top: 0;
              }

              &::before {
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 7px solid #0a3e58;
                counter-increment: list;
                content: '';
                display: block;
                height: 0;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
              }
            }
          }

          h4 {
            font-size: 1.75rem;
            display: block;
            line-height: 1.75rem;
            margin: 0 0 0.5rem;
            padding: 0 0 0.5rem;
            text-align: left;

            @media screen and (max-width: 480px) {
              font-size: 4rem;
              line-height: 4rem;
              padding: 0 0 2rem;
            }

            &.top-space {
              margin-top: 4rem;
            }
          }

          ul {
            display: block;
            box-shadow: none;
            padding: 0;
            width: 100%;

            @media screen and (max-width: 480px) {
              padding-bottom: 6rem;
            }
            
            li {
              display: block;
              font-size: 1.25em;
              line-height: 2rem;
              padding: 2rem 2rem 0 0;
              text-align: left;
              width: 100%;

              @media screen and (max-width: 480px) {
                font-size: 3rem;
                padding: 3rem 0 0;
              }

              &:nth-child(1) {
                padding-top: 0;
              }

              .main-info {
                @media screen and (max-width: 480px) {
                  width: 100%;
                }

                p {
                  display: inline-block;
                  letter-spacing: 0.05rem;

                  @media screen and (max-width: 480px) {
                    font-size: 3rem;
                    line-height: 5rem;
                  }
                }

                .time {
                  color: #a2a7ab;
                  float: right;

                  @media screen and (max-width: 480px) {
                    font-size: 3rem;
                  }
                }
              }

              .prog-bar {
                background-color: #ececec;
                border-radius: 10px;
                height: 5px;
                margin-top: 0.5rem;
                overflow: hidden;
                position: relative;
                width: 100%;

                .bar {
                  animation: progress-100 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-0 {
                  animation: progress-0 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-10 {
                  animation: progress-10 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-20 {
                  animation: progress-20 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-40 {
                  animation: progress-40 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-60 {
                  animation: progress-60 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-80 {
                  animation: progress-80 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }

                .bar-100 {
                  animation: progress-100 3s normal;
                  animation-fill-mode: forwards;
                  background-color: #FFCA28;
                  height: 100%;
                  position: absolute;
                }
              }
            }
          }
        }
      }
    }
  }
}

.work_box {
  display: block;
  margin: 0 auto;
  padding: 4rem 0 3rem;;
  position: relative;
  text-align: center;
  width: 80%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  .filter-container {
    padding-bottom: 5rem;
    width: auto;

    button {
      background-color: #e9eff2;
      display: inline-block;
      margin-right: 3rem;
      padding: 1rem 3rem;

      @media screen and (max-width: 480px) {
        font-size: 3rem;
        margin-bottom: 3rem;
        padding: 2.75rem 3rem;
      }

      &.active {
        background-color: #c1d4de;
      }
    }
  }

  .works {
    display: grid;
    grid-column-gap: 3.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-columns: 33% 33% 33%;
    grid-row-gap: 2rem;

    @media screen and (max-width: 480px) {
      grid-template-columns: 50% 50%;
      width: 100%;
    }
  }

  ul {
    display: block;
    margin: 0 auto;
    position: relative;
    text-align: left;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    li {
      @include box-sizing(border-box);
      display: inline-block;
      height: auto;
      margin-left: 3.5rem;
      vertical-align: top;
      width: 100%;

      @media screen and (max-width: 480px) {
        margin-bottom: 5rem;
      }

      &:nth-child(n + 4) {
        margin-top: 4rem;
      }
      
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      @media screen and (max-width: 480px) {

        &:nth-child(n + 4) {
          margin-top: 0;
        }
        
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(n + 3) {
          margin-top: 3rem;
        }
        
        &:nth-child(n + 1) {
          margin-left: 0;
        }
        
        &:nth-child(2n) {
          margin-left: 2rem;
        }
      }

      a {
        background-color: transparent;
        display: block;
        margin-bottom: 1rem;
        position: relative;
        width: 100%;

        @media screen and (max-width: 480px) {
          margin-bottom: 2.5rem;
          max-height: calc(50vw * 0.47);
        }

        img {
          @include box-sizing(border-box);
          background-color: transparent;
          border: 1px solid #d3dade;
          box-sizing: border-box;
          height: calc(30vw * 0.5);
          object-fit: cover;
          transition: 0.5s;
          width: 100%;

          @media screen and (max-width: 480px) {
            height: calc(50vw * 0.5);
          }

          &:hover {
            opacity: 0.3;
          }
        }
      }

      p {
        display: block;
      }

      .during {
        p {
          font-size: 1rem !important;
          font-weight: 700;

          @media screen and (max-width: 480px) {
            font-size: 2.75rem !important;
            line-height: 4.5rem;
          }

          span {
            color: #a2a8ab;
            font-size: 1rem !important;
            font-weight: 500;

            @media screen and (max-width: 480px) {
              font-size: 2.75rem !important;
              line-height: 4.5rem;
            }
          }
        }
      }

      .title {
        font-size: 1.15rem !important;
        line-height: 1.15rem;
        letter-spacing: 0.1rem;
        margin: 0 0 0.25rem;

        @media screen and (max-width: 480px) {
          font-size: 3rem !important;
          line-height: 4rem;
          margin: 0 0 1.5rem;
        }
      }

      .type {
        color: #a2a8ab;
        font-size: 0.9rem !important;
        padding-bottom: 5rem;

        @media screen and (max-width: 480px) {
          font-size: 2.75rem !important;
          line-height: 4.5rem;
        }
      }
    }
  }
}

.work_box_top {
  display: block;
  margin: 0 auto;
  padding: 4rem 0 0;
  position: relative;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }

  ul {
    display: block;
    margin: 0 auto;
    position: relative;
    text-align: left;
    width: 100% !important;

    li {
      @include box-sizing(border-box);
      display: inline-block;
      height: auto;
      padding: 0 1.25rem;
      vertical-align: top;
      width: 32.8%;

      @media screen and (max-width: 480px) {
        margin-bottom: 5rem;
        width: 50%;

        &:nth-child(3) {
          display: none;
          padding-left: 0;
        }
      }

      a {
        background-color: transparent;
        display: block;
        margin-bottom: 1rem;
        position: relative;
        width: 100%;

        @media screen and (max-width: 480px) {
          margin-bottom: 2.5rem;
        }

        img {
          @include box-sizing(border-box);
          background-color: transparent;
          border: 1px solid #d3dade;
          box-sizing: border-box;
          height: calc(30vw * 0.5);
          object-fit: cover;
          transition: 0.5s;
          width: 100%;

          @media screen and (max-width: 480px) {
            height: calc(50vw * 0.5);
          }

          &:hover {
            opacity: 0.3;
          }
        }
      }

      p {
        display: block;
      }

      .during {
        p {
          font-size: 1rem !important;
          font-weight: 700;

          @media screen and (max-width: 480px) {
            font-size: 2.75rem !important;
            line-height: 4.5rem;
          }

          span {
            color: #a2a8ab;
            font-size: 1rem !important;
            font-weight: 500;

            @media screen and (max-width: 480px) {
              font-size: 2.75rem !important;
              line-height: 4.5rem;
            }
          }
        }
      }

      .title {
        font-size: 1.15rem !important;
        line-height: 1.15rem;
        letter-spacing: 0.1rem;
        margin: 0 0 0.25rem;

        @media screen and (max-width: 480px) {
          font-size: 3rem !important;
          line-height: 4rem;
          margin: 0 0 1.5rem;
        }
      }

      .type {
        color: #a2a8ab;
        font-size: 0.9rem !important;
        padding-bottom: 5rem;

        @media screen and (max-width: 480px) {
          font-size: 2.75rem !important;
          line-height: 4.5rem;
        }
      }
    }
  }
}

.title_area {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 5rem;
    margin: 4rem auto;
    width: 100% !important;
  }

  .title_body {
    margin: 0 auto;
    width: 80%;

    @media screen and (max-width: 480px) {
      width: 90%;
    }

    h1 {
      font-size: 2.5rem;
      margin: 0 0 1.5rem;

      @media screen and (max-width: 480px) {
        font-size: 5rem;
        letter-spacing: 0.3rem;
        line-height: 9rem;
      }
    }

    h2 {
      font-size: 1.75rem;
      margin: 0 0 1rem;
      text-align: left;

      @media screen and (max-width: 480px) {
        font-size: 4rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 0;
      text-align: left;

      @media screen and (max-width: 480px) {
        font-size: 3.5rem;
      }
    }
  }
}

.work_detail_box {
  margin: 0 auto;
  padding-bottom: 0;
  width: 80%;

  @media screen and (max-width: 480px) {
    width: 95% !important;
  }

  img {
    @include box-sizing(border-box);
    border: 1px solid #d3dade;
    height: auto;
    transition-duration: 0.3s;
    width: 100%;
    
    @media screen and (max-width: 480px) {
      margin-bottom: 4rem;
    }
  }

  ul {
    margin-top: 2rem;

    li {
      display: inline-block;

      &:nth-child(1) {
        width: 46.5%;
    
        @media screen and (max-width: 480px) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        padding-left: 4rem;
        width: 52.5%;
    
        @media screen and (max-width: 480px) {
          padding-left: 0;
          width: 100%;
        }
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
        margin: 0 0 1.25rem;
    
        @media screen and (max-width: 480px) {
          font-size: 3.25rem;
          line-height: 5.5rem;
          margin-bottom: 4rem;
        }

        &.type {
          color: #95999c;
          font-size: 1rem;
          margin: 0;
    
          @media screen and (max-width: 480px) {
            font-size: 3.15rem;
            margin-bottom: 0.25rem;
          }
        }
      }

      h1 {
        font-size: 2rem;
        margin: 0 0 2rem;
    
        @media screen and (max-width: 480px) {
          font-size: 4.25rem;
          margin: 1rem 0 4rem;
        }
      }

      a {
        &:hover {
          img {
            opacity: 0.7;
            transition-duration: 0.3s;
          }
        }
      }

      table {
        margin-top: 4rem;
        width: 100%;
    
        @media screen and (max-width: 480px) {
          margin-top: 8rem;
        }

        tr {
          th, td {
            color: #95999c;
            font-size: 1.1rem;
            letter-spacing: 0.05rem;
            line-height: 2rem;
            padding: 0 0 1.5rem;
            vertical-align: top;
    
            @media screen and (max-width: 480px) {
              display: block;
              font-size: 3rem;
              letter-spacing: 0.1rem;
              line-height: 3.5rem;
            }
          }

          th {
            width: 30%;
    
            @media screen and (max-width: 480px) {
              padding: 0 0 1.5rem;
            }
          }
          td {
            width: 70%;
    
            @media screen and (max-width: 480px) {
              padding: 0 0 6rem;
            }
          }
        }
      }
    }
  }

  .other_works {
    font: {
      size: 1.5rem;
      weight: 400;
    }
    letter-spacing: 0.05rem;
    margin-top: 8rem;

    @media screen and (max-width: 480px) {
      font: {
        size: 3.25rem;
        weight: 400;
      }
      margin-top: 7rem;
    }
  }

  .swiper {
    width: 100% !important;
  }

  .swiper-slide {
    img {
      height: 8rem;
      object-fit: cover;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: 16em;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 1rem;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    bottom: 0;
    content: '';
    display: block;
    height: 11px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 11px;

    @media screen and (max-width: 480px) {
      top: -3.5rem;
    }
  }

  .swiper-button-prev::after {
    left: 0.75rem;
    border-left: 3px solid #d3dade;
    border-top: 3px solid #d3dade;
    transform: rotate(-45deg);
  }

  .swiper-button-next::after {
    right: 0.75rem;
    border-right: 3px solid #d3dade;
    border-top: 3px solid #d3dade;
    transform: rotate(45deg);
  }
}

.description_box {
  margin: 0 auto;
  width: 50%;
    
  @media screen and (max-width: 480px) {
    width: 95% !important;
  }

  p {
    font-size: 1.25rem;
    
    @media screen and (max-width: 480px) {
      font-size: 2.75rem;
    }
  }
}

.back {
  color: #95999c !important;
  display: block;
  font-size: 0.85rem;
  letter-spacing: 1px;
  margin-top: 5rem;
  text-align: left;

  @media screen and (max-width: 480px) {
    font-size: 3rem;
    margin-top: 5rem;
  }

  &:hover {
      color: #c1c6c9 !important;

      i {
        color: #c1c6c9 !important;
      }
  }

  i {
      color: #95999c !important;
      font-size: 0.75rem;

      @media screen and (max-width: 480px) {
        font-size: 2.5rem;
      }
  }
}

.paging {
  margin: 0 auto;
  padding-bottom: 3rem;
  padding-top: 4rem;
  position: relative;
  text-align: center;
  height: auto;
  width: 100%;

  @media screen and (max-width: 480px) {
    padding-top: 8rem;
  }

  ul {
    display: inline-block;
    margin: 0 auto 3rem;
    padding: 0;
    width: auto;

    @media screen and (max-width: 480px) {
      width: auto;
    }

    li {
      border-radius: 12.5%;
      display: inline-block;
      height: 2.25rem;
      margin: 0 0.375rem;
      width: 2.25rem;

      @media screen and (max-width: 480px) {
        height: 5rem;
        margin: 0 0.75rem;
        width: 5rem;
      }

      &:first-child {
        margin-right: 1.5rem;

        @media screen and (max-width: 480px) {
          margin-right: 3.5rem;
        }
      }

      &:last-child {
        margin-left: 1.5rem;

        @media screen and (max-width: 480px) {
          margin-left: 3.5rem;
        }
      }

      a {
        color: #0a3e58;
        font-size: 1.35rem;
        padding: 1.5rem 0;
        text-align: center;

        @media screen and (max-width: 480px) {
          font-size: 3.5rem;
          line-height: 5rem;
          height: 5rem;
          width: 5rem;
        }
      }

      .arrow {
        display: block;
        position: relative;

        &::before {
          content: '';
          display: block;
          height: 0.5rem;
          margin-top: 0;
          position: absolute;
          top: 50%;
          width: 0.5rem;

          @media screen and (max-width: 480px) {
            height: 1.2rem;
            top: 25%;
            width: 1.2rem;
          }
        }

        &::after {
          @include transform(rotate(45deg));
          content: '';
          display: block;
          height: 0.5rem;
          margin-top: -0.25rem;
          position: absolute;
          top: 17.5%;
          width: 0.5rem;

          @media screen and (max-width: 480px) {
            height: 1.2rem;
            top: 25%;
            width: 1.2rem;
          }
        }
      }

      .prev {
        &::before {
          right: 0;
        }

        &::after {
          border-left: solid 2px #0a3e58;
          border-bottom: solid 2px #0a3e58;
          right: 5px;
        }
      }

      .next {
        &::before {
          left: 0;
        }

        &::after {
          border-right: solid 2px #0a3e58;
          border-top: solid 2px #0a3e58;
          left: 5px;
        }
      }

      .disable {
        pointer-events: none;
      }
    }

    .selected {
      background-color: #0a3e58;

      a {
        color: #fff !important;
        padding-left: 0.2rem;
      }
    }
  }
}

.current-page {
  background-color: #0a3e58;

  a {
    background-color: transparent;
    color: #fff !important;
    padding: 0.5rem !important;
  }
}

.pagination {
  li {
    cursor: pointer;
  }

  .disabled-button {
    cursor: default;
  }
}

.contact_box {
  display: block;
  left: 0;
  margin: 4rem auto 0;
  padding: 2rem 3rem;
  position: relative;
  right: 0;
  width: 50%;

  @media screen and (max-width: 480px) {
    margin: 8rem auto 0;
    padding: 5rem 0;
    width: 100%;
  }

  .notice_box {
    border: 2px solid #d3dff0;
    margin-bottom: 1.5rem;
    padding: 1rem;

    p {
      font-size: 0.9rem;

      @media screen and (max-width: 480px) {
        font-size: 3rem;
      }
    }
  }

  .form-group {

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      line-height: 5.5rem;
    }
  }

  label {
    color: #424c57;
    font-size: 1.5rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
    }
  }

  input {
    -webkit-appearance: none;
    background-color: transparent;
    border-bottom: 1px solid #6b7b8c;
    border-radius: 0;
    margin-bottom: 3rem;
    padding: 1rem 1rem 0.75rem 1rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      margin-bottom: 5rem;
      padding: 2rem 1rem 1rem;
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 50000s ease-in-out 0s;
    }
  }
  
  input[type="checkbox"] {
    @include box-sizing(border-box);
    border: none;
    height: 0; width: 0;

    & + label {
      align-items: center;
      display: flex;
      margin: .6em 0;
      position: relative;
      transition: color 250ms cubic-bezier(.4,.0,.23,1);

      span {
        align-items: center;
        background: transparent;
        border: 1px solid #0A3E58;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        height: 1em;
        justify-content: center;
        margin-right: 1em;
        transition: all 250ms cubic-bezier(.4,.0,.23,1);
        width: 1em;
      }

      a {
        color: #07838c;
      }
    }

    &:checked + label {
      span {
        animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
        background-color: #0A3E58;

        &::before {
          @include transform(rotate(45deg));
          animation: checkbox-check 125ms 250ms cubic-bezier(.4, .0, .23, 1) forwards;
          border-bottom: 3px solid transparent;
          border-right: 3px solid transparent;
          content: "";
          left: .2em;
          position: absolute;
          top: 0.45em;
          transform-origin: 0% 100%;

          @media screen and (max-width: 480px) {
            top: 0.425em;
          }
        }
      }
    }
  }

  textarea {
    background-color: transparent;
    border-bottom: 1px solid #6b7b8c;
    height: 17rem;
    margin-bottom: 2rem;
    padding: 1rem 1rem 0.75rem 1rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      height: 28rem;
      margin-bottom: 5rem;
      padding: 2rem 1rem;
    }
  }

  textarea:focus {
    outline: none;
    text-decoration: none;
  }

  @keyframes shrink-bounce {
    0%{ 
      @include transform(scale(1));
    }
    33% {    
      @include transform(scale(.85));
    }
    100% {
      @include transform(scale(1));
    }
  }

  @keyframes checkbox-check {
    0% {
      @include transform(translate3d(0,0,0) rotate(45deg));
      border-color: #FFF;
      height: 0;
      width: 0;
    }
    33% {
      @include transform(translate3d(0,0,0) rotate(45deg));
      height: 0;
      width: .2em;
    }
    100% {
      @include transform(translate3d(0,-.5em,0) rotate(45deg));
      border-color: #FFF;
      height: .5em;
      width: .2em;
    }
  }

  input[type=submit] {
    background-color:  #0A3E58;
    border: none;
    color: #fff;
    font-size: 1.6rem;
    margin: 5rem auto 0;
    padding: 1rem 3rem;
    transition: 0.5s;
    width: 40%;

    @media screen and (max-width: 480px) {
      font-size: 4rem;
      margin-top: 5rem;
      padding: 4rem;
    }

    &:hover {
      background-color: #0c5478;
    }
  }

  .complete {
    margin: 0 auto;
    text-align: center;

    h5 {
      border: 2px solid #424c57;
      color: #424c57;
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 0 3rem 0;
      padding: 0.75rem 1.5rem;
      text-align: center;
      width: auto;

      @media screen and (max-width: 480px) {
        font-size: 4.5rem;
      }
    }

    p {
      color: #424c57;
      text-align: left;
      font-size: 1.5rem;
      line-height: 3rem;
      margin-top: 0.75rem;

      @media screen and (max-width: 480px) {
        font-size: 3.5rem;
        line-height: 6rem;
        margin-top: 2rem;
      }
    }

    button {
      background-color: #0A3E58;
      border: none;
      color: #fff;
      font-size: 1.5rem;
      margin: 2rem auto 0;
      padding: 1rem 3rem;
      transition: 0.5s;
      width: auto;

      @media screen and (max-width: 480px) {
        font-size: 3.5rem;
        line-height: 5rem;
        margin-top: 6rem;
        padding: 3rem 5rem;
      }

      &:hover {
        background-color: #0c5478;
      }
    }
  }
}

/* ローディング画面 */
#loading {
	animation-name: fadeOut;
	animation-duration: 1.5s;
	animation-iteration-count: 1;
  height: 100vh;
  left: 0;
  background-color: #0A3E58;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-duration: 1.8s;
  width: 100vw;
  z-index: 9999;
}

#loading img {
	animation-name: dot;
	animation-duration: 0.5s;
	animation-iteration-count: 6;
	opacity: 1;
  bottom: 0;
	display: block;
	left: 0;
	margin: auto;
	position: absolute;
  right: 0;
	top: 0;
	width: 12.5%;

  @media screen and (max-width: 480px) {
    top: -20rem;
	  width: 30%;
  }
}

.privacy_box {
  display: block;
  left: 0;
  margin: 4rem auto 0;
  padding: 2rem 3rem;
  position: relative;
  right: 0;
  width: 50%;

  @media screen and (max-width: 480px) {
    margin: 8rem auto 0;
    padding: 5rem 0;
    width: 100%;
  }

  h5 {
    color: #424c57;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 3rem 0 1rem;
    text-align: left;

    @media screen and (max-width: 480px) {
      font-size: 4rem;
      margin: 6rem 0 0;
    }
  }

  p {
    color: #424c57;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 2rem;
    margin-top: 0.75rem;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      line-height: 5.75rem;
      margin-bottom: 4rem;
      margin-top: 2rem;
    }
  }

  .list {
    p {
      &.indent-1 {
        font-size: 1.2rem;
        padding-left: 1.25rem;
        text-indent: -1rem;

        @media screen and (max-width: 480px) {
          font-size: 3.5rem;
          line-height: 5.75rem;
        }
      }
    }
  }
}

.common_box {
  display: block;
  left: 0;
  margin: 4rem auto 0;
  padding: 2rem 3rem;
  position: relative;
  right: 0;
  width: 50%;

  @media screen and (max-width: 480px) {
    margin: 8rem auto 0;
    padding: 5rem 0;
    width: 100%;
  }

  h5 {
    color: #424c57;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 3rem 0 1rem;
    text-align: left;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      margin: 5rem 0 1rem;
    }
  }

  p {
    color: #424c57;
    text-align: left;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-top: 0.75rem;

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      line-height: 6rem;
      margin-top: 2rem;
    }
  }

  .list {
    p.indent-1 {
      font-size: 1.25rem;
      padding-left: 1em;
      text-indent: -1em;
    }

    @media screen and (max-width: 480px) {
      font-size: 3.5rem;
      line-height: 6rem;
    }
  }
}

.post_top {
  padding: 4rem 0 3rem;
}

.loaded {
  opacity: 0;
  visibility: hidden;
  z-index: -1 !important;
}

.indicator_01 { z-index: 9998; }
.indicator_02 { animation-delay:1s;z-index: 9997; }
.indicator_03 { animation-delay:2s;z-index: 9996; }
.indicator_04 { animation-delay:3s;z-index: 9995; }
.indicator_05 { animation-delay:4s;z-index: 9994; }

@keyframes dot {
  0%  { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes fadeOut {
  0% {opacity: 1}
  75% {opacity: 1}
  100% {opacity: 0}
}

@-webkit-keyframes fadeOut {
  0%   {opacity: 1}
  75% {opacity: 1}
  100% {opacity: 0}
}

footer {
  background-color: #0a3e58;
  display: block;
  position: relative;

  .box {
    color: #fff;
    display: block;
    padding: 2.5rem 0 0;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 3rem;
      letter-spacing: 0.5rem;
      padding: 5.5rem 0;
    }
  }

  .subbox {
    text-align: right;
    width: 100%;

    @media screen and (max-width: 480px) {
      text-align: center;
    }

    ul {
      padding: 0 2.5rem 2.5rem;

      @media screen and (max-width: 480px) {
        padding: 0 0 5rem;
      }

      li {
        margin-left: 2.5rem;

        @media screen and (max-width: 480px) {
          font-size: 2.5rem;
        }

        a {
          color: #cbcfd1;
        }
      }
    }
  }
}

.animation-scroll-show {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 1s;
  }

.animation-scroll-show.is-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}